import React, { useState } from 'react';
import VideoModal from '../VideoModal';
import Image from 'gatsby-image';

const VideoPlaceholder = ({ src, posterFluid }) => {
  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    setShowModal(true);
  };

  return (
    <div className="video-placeholder">
      {posterFluid && (
        <Image
          backgroundColor={true}
          className="video-placeholder__poster"
          fluid={posterFluid}
          onClick={handleClick}
        />
      )}
      <button className="video-placeholder__play-button" onClick={handleClick}>Play Button</button>
      <VideoModal
        onRequestClose={() => {
          setShowModal(false);
        }}
        isOpen={showModal}
        videoSrc={src}
        videoPoster={posterFluid?.src}
      />
    </div>
  );
};

VideoPlaceholder.defaultProps = {
  ar: 16 / 9,
};

export default VideoPlaceholder;
