import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import ThankYouPageDetail from '../components/ThankYouPageDetail';
import SiteMeta from '../components/SiteMeta';
import MediaHeroThankYouPage from '../components/MediaHeroThankYouPage';

const ThankYouPagePage = ({ data, pageContext }) => {

  const {
    contentfulThankYouPage: {
      downloadFile,
      downloadTitle,
      downloadIcon,
      messageTitle,
      messageBody,
      image: {
        fluid,
      },
      heroModule,
      metaTitle,
      metaDescription: {
        metaDescription,
      },
    },
  } = data;

  return (
      <Layout>
        <SiteMeta description={metaDescription} title={metaTitle}/>
        <MediaHeroThankYouPage {...heroModule} />
        <ThankYouPageDetail {...data.contentfulThankYouPage}/>
      </Layout>
  );
};

export default ThankYouPagePage;

export const query = graphql`
  query ThankYouPagePageQuery($contentful_id : String!) {
    contentfulThankYouPage(contentful_id : { eq: $contentful_id  }){
      ...ThankYouPageDetail
      heroModule {
        ...Navigator
      }
    }
  }
`;
