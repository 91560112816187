import React, {Children, Fragment, useEffect} from 'react';
import RichText from '../RichText';
import {useCookie} from 'react-use';
import {Form} from '../Form';
import {Tabs, Tab} from '../Tabs';
import {BoxPlot} from '../BoxPlot';
import Image from 'gatsby-image';
import {Button} from '../Button';
import MediaHeroProducts from '../MediaHeroProducts';

const TYPageDetailBody = (props) => {
    const {
        image,
        messageTitle,
        messageBody,
        downloadFile,
        downloadTitle,
        externalUrl,
        downloadIcon,
        buttonTheme,
    } = props;

    let buttonThemeName;

    switch (buttonTheme) {
        case 'Black':
            buttonThemeName = 'light';
            break;
        case 'Orange':
            buttonThemeName = 'dark';
            break;
        case 'Yellow':
            buttonThemeName = 'yellow';
            break;
    }

    console.log(buttonTheme);
    console.log(buttonThemeName);

    return (
        <div className="ty-page-detail__body">
            <div className="ty-page-detail__columns">
                <div className="ty-page-detail__column ty-page-detail__column--content">
                    <div>
                        <a
                            href={externalUrl}
                            target="_blank"
                        >
                            <Image
                                className="ty-page-icon"
                                imgStyle={{objectFit: 'contain'}}
                                fluid={downloadIcon?.fluid}
                            />
                        </a>
                    </div>
                    <div>
                        {messageTitle &&
                            <h2>{messageTitle}</h2>
                        }
                        <RichText source={messageBody?.json}/>
                    </div>
                    <div>
                        <Button
                            theme={buttonThemeName}
                            href={externalUrl}
                        >
                            {downloadTitle}
                        </Button>
                    </div>
                </div>
                <div className="ty-page-detail__column ty-page-detail__column--image">
                    <a
                        href={externalUrl}
                        target="_blank"
                    >
                        <Image
                            imgStyle={{objectFit: 'contain'}}
                            fluid={image?.fluid}
                        />
                    </a>
                </div>
            </div>
        </div>
    );
};

const ThankYouPageDetail = (props) => {
    const {form} = props;

    return (
        <div className="ty-page-detail">
            <div className="ty-page-detail__inner">
                <TYPageDetailBody {...props} />
            </div>
        </div>
    );
};

ThankYouPageDetail.defaultProps = {
    downloadIcon: {
        fluid: {
            url: '',
        },
    },
};

export default ThankYouPageDetail;
