import React, { useState, Children } from 'react';
import { TabButton } from './TabButton';

const Tabs = ({ className, children }) => {
  const [ activeTab, setActive ] = useState(children[0].props.label);
  const changeTab = (activeTab) => {
    setActive(activeTab);
  };

  let content;
  let buttons = [];

  return (
      <div className={className}>
        {Children.map(children, child => {
          buttons.push({
            label: child.props.label,
            theme: child.props.theme,
            href: child.props.href,
          });
          if (child.props.label === activeTab)
            content = child.props.children;
        })}
        <TabButtons activeTab={activeTab} buttons={buttons} changeTab={changeTab}/>
        <div className="tab-content">
          {content}
        </div>
      </div>
  );
};

const TabButtons = ({ buttons, changeTab, activeTab }) => {
  return (
      <div className="tab-buttons">
        {buttons.map((button, key) => {
          return (
              <TabButton
                  key={key}
                  theme={button.theme}
                  href={button.href}
                  className={button.label === activeTab ? 'active' : '' || button.href !== undefined ? 'link' : ''}
                  onClick={() => {
                    if (button.href === undefined)
                      changeTab(button.label);
                  }}
              >
                {button.label}
              </TabButton>
          );
        })}
      </div>
  );
};

export { Tabs };
