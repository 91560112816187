import React from 'react';
import RichText from '../RichText';
import {Button} from '../Button';

function MediaHeroThankYouPage(props) {
    const {
        eyebrow,
        eyebrowColor,
        title,
        titleColor,
        bodyText,
        bodyTextColor,
        asset,
        videoCoverImage,
        heroBg,
        heroBgColor,
    } = props;
    const heroBgImage = heroBg?.file.url;

    return (
        <section className="ty-page-media-hero">
            <div className="ty-page-media-hero__container">
                <div className="ty-page-media-hero__background-image" style={{
                    backgroundColor: heroBgColor,
                    backgroundImage: `url(${heroBgImage})`,
                    backgroundSize: 'auto 100%',
                    backgroundPosition: 'top right',
                    backgroundRepeat: 'no-repeat',
                    minHeight: '320px',
                }}/>
                <div className="ty-page-media-hero__inner">
                    <div className="ty-page-media-hero__content">
                        {eyebrow &&
                            <p className="ty-page-media-hero__eyebrow"
                               data-sal="slide-up"
                               data-sal-delay="200"
                               data-sal-duration="1000"
                               data-sal-easing="ease"
                               style={{
                                   color: eyebrowColor
                               }}
                            >{eyebrow}</p>
                        }
                        <h1 className="ty-page-media-hero__title"
                            data-sal="slide-up"
                            data-sal-delay="200"
                            data-sal-duration="1000"
                            data-sal-easing="ease"
                            style={{
                                color: titleColor
                            }}
                        >{title}</h1>
                        <div className="ty-page-media-hero__body"
                             data-sal="slide-up"
                             data-sal-delay="200"
                             data-sal-duration="1000"
                             data-sal-easing="ease"
                             style={{
                                 color: bodyTextColor
                             }}
                        >
                            <RichText source={bodyText.json}/>
                        </div>
                        <Button theme="dark" href="#form" className="ty-page-media-hero__mobile-cta">Download the
                            eBook</Button>
                    </div>
                </div>
            </div>
        </section>
    );
}

MediaHeroThankYouPage.defaultProps = {
    title: 'Lorem ipsum dolor sit amet',
    bodyText: 'Duis ligula magna, egestas ac ipsum sit amet, mattis sodales nulla. Duis iaculis consectetur iaculis. Mauris ultricies et nisi vitae tempor.',
    asset: {
        file: {
            url: '',
        },
    },
    heroBg: {
        file: {
            url: '',
        },
    },
};

export default MediaHeroThankYouPage;
