import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

const VideoModal = ({ videoSrc, videoPoster, onRequestClose, ...rest }) => {
  const modalOptions = {
    portalClassName: 'modal__portal',
    overlayClassName: 'modal__overlay',
    className: 'modal__window',
    onRequestClose: onRequestClose,
    shouldFocusAfterRender: false,
  };
  return (
    <Modal {...modalOptions} {...rest}>
      <video
        src={videoSrc}
        autoPlay
        poster={videoPoster}
        controls
        onEnded={() => onRequestClose()}
      />
    </Modal>
  );
};

VideoModal.propTypes = {
  videoSrc: PropTypes.string,
  videoPoster: PropTypes.string,
};

VideoModal.defaultProps = {
  videoSrc: '',
  videoPoster: '',
};

export default VideoModal;
